import React from "react";
import I18nMessage from "./i18n/I18nMessage";
import {Typography, withStyles, createStyles} from "@material-ui/core";
import PortalLink from "./PortalLink";

const sectionItemStyles = createStyles((theme: any) => ({
    sectionItem: theme.sectionItem,
    sectionItemTitle: theme.sectionItemTitle,
    sectionItemDesc: theme.sectionItemDesc
}));

const SectionItem = (props: any) => (
    <PortalLink href={props.href} className={`${props.classes.sectionItem} ${props.className}`}>
        <I18nMessage id={props.title} variant="h2" className={props.classes.sectionItemTitle}/>
        <Typography className={props.classes.sectionItemDesc}>{props.shortDescription || props.shortdesc}</Typography>
    </PortalLink>
);

export default withStyles(sectionItemStyles, { name: "SectionItem" })(SectionItem);
import merge from "deepmerge";

export const applyStructureMeta = (item)=> {
    return {...item, ...(item.meta || []).reduce((acc, m)=> {
            const key = m.name || m.id;
            acc[key] = m.value;
            return acc;
        }, {})}
}
const reduceMeta = (content, reducer, acc = {}):any=>{
    return Object.entries(merge.all([content.standardMetadata || {}, content.customMetadata || {}])).reduce((acc, [, metaVal])=>{
        Object.entries(metaVal).forEach(([key, val]:any)=> {
            acc = reducer(acc, key, val);
        });
        return acc;
    }, acc);
}
export const getContentMetadataArray = (content):any[] => {
    return reduceMeta(content,(acc, key, val)=>{
        acc.push({
            key,
            value: val.value || val
        })
        return acc;
    }, [])
}

export const getContentMetadataObj = (content):any => {
    return reduceMeta(content,(acc, key, val)=>{
       acc[key] = val.value || val;
       return acc;
    }, {});
}
import React from "react";
import Head from "next/head";
import {constructSeoTitle, kebabCase} from "../utils/string";
import {getContentMetadataArray} from "../utils/metadata";
import {IPortalSeo} from "@jorsek/content-portal-configs/types";

export interface ILayoutProps {
    seo?: IPortalSeo;
    content?: {
        shortDescription: string;
        keywords: string[];
        alternateLanguages: string[];
        metadata?: []
    }
    meta?: [];
    keywords?: string[];
    title?: string;
}

class ContentMeta extends React.Component<ILayoutProps, {}> {
    static defaultProps = {
        seo: {title: ""},
        title: "",
        content: {},
        meta: [],
        keywords: []
    }

    render() {
        let seoTitle = constructSeoTitle(this.props.content, this.props.seo?.title || this.props.title)
        let seoDesc = this.props.content?.shortDescription || this.props.seo?.description;
        let seoKeywords = this.props.content?.keywords ? this.props.content?.keywords : (this.props.seo.keywords || this.props.keywords || []);
        const totalMetaData = (getContentMetadataArray(this.props.content) as any[])?.filter(({
                                                                                                  type,
                                                                                                  datatype
                                                                                              }) => type !== "html" && datatype !== "http-meta");
        const alternateLanguages = Object.entries(this.props.content?.alternateLanguages || {}).map(([locale, val]: any) => ({
            locale,
            href: `/${locale}/${val.href}`
        }))
        return (
            <Head>
                <title>{seoTitle}</title>
                <meta name="keywords" content={seoKeywords.join(',')} key="keywords"/>
                <meta name="description" content={seoDesc} key="description"/>
                {alternateLanguages.map(({locale, href}) => <link key={href} rel="alternate"
                                                                  hrefLang={locale} href={href}/>)}
                {this.props.meta?.filter(({
                                              type,
                                              datatype
                                          }) => type === "html" || datatype === "http-meta").map(({
                                                                                                      name,
                                                                                                      id,
                                                                                                      value
                                                                                                  }, i) => {
                    let key = name || id;
                    return <meta key={key || i} name={key} id={key} httpEquiv={kebabCase(key)}
                                 content={value}/>
                })}
                {this.props.content.metadata?.map(({name, content}) => {
                    let key = name;
                    return <meta key={key} name={key} content={content}/>
                })}
                {totalMetaData.map(({key, value}) => <meta key={key} id={key} name={key}
                                                           httpEquiv={kebabCase(key)}
                                                           content={value}/>)}
            </Head>
        );
    }
}

export default ContentMeta;

import React from 'react';
import MUILink from '@material-ui/core/Link';
import {createStyles, withStyles} from '@material-ui/core';
import Layout from "../../components/Layout"
import I18nMessage from "../../components/i18n/I18nMessage";
import PortalLink from "../../components/PortalLink";
import {errorPageServerSideProps} from "../../utils/error/errorPageServerSideProps";

const errorPageStyles = createStyles((theme: any) => ({
    errorPage: theme.errorPage,
    errorPageMessage: theme.errorPageMessage,
    errorPageNextStepsMessage: theme.errorPageNextStepsMessage,
    errorPageContainer: theme.errorPageContainer,
    errorPageKnownIssuesLink: theme.errorPageKnownIssuesLink,
    errorPageKnownIssuesText: theme.errorPageKnownIssuesText,
    errorPageSiteSectionHref: theme.errorPageSiteSectionHref,
    errorPageSiteSectionIcon: theme.errorPageSiteSectionIcon,
    errorPageSiteSectionLinks: theme.errorPageSiteSectionLinks,
    errorPageSiteSectionTitle: theme.errorPageSiteSectionTitle,
    errorPageSiteSectionDescription: theme.errorPageSiteSectionDescription,
}));

interface IErrorPageProps {
    classes?: any;
    error?: any;
    statusCode?: any;
    siteSections?: any;
    layoutProps?: any;
    runtimeConfig?: any;
    externalSites?: any;
}

class ErrorPage extends React.Component<IErrorPageProps> {
    static defaultProps = {
        externalSites: [],
        siteSections: [],
        runtimeConfig: {},
        errorCode: ""
    }

    render = () => {
        const props = this.props as any;
        const combinedSiteSections = [...props.siteSections, ...props.externalSites];
        const i18nContext = {status: props.statusCode, "@key": "status", "@plural": 'http.error'}
        return (
            <Layout {...props.layoutProps} className={'error-index'}>
                <div className={props.classes.errorPageContainer}>
                    <I18nMessage id={"message.error.status"} values={i18nContext}
                                 className={props.classes.errorPageMessage}/>
                    <I18nMessage id={"message.error.next-steps"} values={i18nContext}
                                 className={props.classes.errorPageNextStepsMessage}/>
                    &nbsp;
                    <I18nMessage id={"message.error.known-issues-link"}
                                 Component={MUILink}
                                 className={props.classes.errorPageKnownIssuesLink}
                                 target="_blank"
                                 component="a"
                                 underline="always"
                                 href={props.runtimeConfig.knownIssuesUrl}/>
                    &nbsp;
                    <I18nMessage Component={'span'}
                                 className={props.classes.errorPageKnownIssuesText}
                                 id={"message.error.known-issues"}/>
                    <div className={props.classes.errorPageSiteSectionLinks}>
                        {combinedSiteSections.map((obj: any, index) => {
                                const thumbnail = obj?.thumbnail || props.runtimeConfig.defaultSectionIcon;
                                let {href} = obj || {};
                                if (obj?.versions?.length) {
                                    href = obj.versions[0].href;
                                }
                                return (
                                    <PortalLink className={props.classes.errorPageSiteSectionHref}
                                                href={href}
                                                key={index}>
                                        {thumbnail ? 
                                            <img alt={"thumbnail"} src={thumbnail} 
                                                 className={`no-alt ${props.classes.errorPageSiteSectionIcon}`} 
                                            />: null}
                                        <I18nMessage id={obj?.label || obj?.title}
                                                     className={props.classes.errorPageSiteSectionTitle}/>
                                        {obj?.shortDescription && <I18nMessage id={obj?.shortDescription}
                                                                              className={props.classes.errorPageSiteSectionDescription}/>}
                                    </PortalLink>)
                            }
                        )}
                    </div>
                </div>
            </Layout>
        );
    }
}

export const getServerSideProps = async ({req, err, res}) => {
    return await errorPageServerSideProps({req, err, res})
}
export default withStyles(errorPageStyles, {name: "ErrorPage"})(ErrorPage);

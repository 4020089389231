import React from "react";
import {makeStyles} from "@material-ui/core";
import RenderHtml from "./RenderHtml";
import I18nMessage from "./i18n/I18nMessage";
import { kebabCase } from "../utils/string";


const siteGroupsStyles = props => makeStyles((theme: any) => {
    let styles = {
        siteGroups: theme.siteGroups,
    }

    for (const key of Object.keys(props.groups) as any) {
        const kebab = kebabCase(key);
        const classString = kebab.charAt(0).toUpperCase() + kebab.slice(1);
        styles[`siteGroups${classString}`] = theme[`siteGroups${classString}`];
        styles[`siteGroups${classString}Title`] = theme[`siteGroups${classString}Title`];
        styles[`siteGroups${classString}Item`] = theme[`siteGroups${classString}Item`];
    }

    return styles;
}, { name: "SiteGroups"})(props);

interface ISiteGroupsProps {
    className?: string;
    classes?: any;
    children?: any;
    groups: any;
}

const GenericGroups = (props: ISiteGroupsProps) => {
    const { groups, children, className} = props;
    const styles = siteGroupsStyles(props) as any;
    const {externalProducts, externalSites,...genericGroups} = groups;
    const groupSections = Object.keys(genericGroups);
    return (groupSections.length ? <div className={`${styles.siteGroups} ${className}`}>
                    {children}
                    {groupSections.map(key => {
                        const kebab = kebabCase(key);
                        const groupObj = groups[key] || [];
                        const classString = kebab.charAt(0).toUpperCase() + kebab.slice(1);
                        const groupChildren = groupObj && groupObj.filter((c)=> !!c);
                        return groupChildren.length ? (
                            <div key={key} className={styles[`siteGroups${classString}`]}>
                                <I18nMessage className={styles[`siteGroups${classString}Title`]} 
                                             variant="h4"
                                             id={`label.${kebab}`}/>
                                {groupChildren.map((child, index) => child && <RenderHtml 
                                    key={key + index} 
                                    className={styles[`siteGroups${classString}Item`]}>{child.content}</RenderHtml>)}
                            </div>
                        ): null;
                    })}
                </div>
        : null);
}

export default GenericGroups as any;

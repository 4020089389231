import React from "react";
import {withStyles, createStyles} from "@material-ui/core";
import {withRouter} from "next/router";
import SectionItem from "./SectionItem";
import {WithRouterProps} from "next/dist/client/with-router";
import I18nMessage from "./i18n/I18nMessage";
import PortalLink from "./PortalLink";
import PortalFontIcon from "./PortalFontIcon";
import RenderHtml from "./RenderHtml";
import {ISection} from "@jorsek/content-portal-client/types";

const siteGroupsStyles = createStyles((theme: any) => ({
    index: theme.index || {},
    siteGroups: theme.siteGroups,
    siteGroupsRelatedResources: theme.siteGroupsRelatedResources,
    siteGroupsRelatedResourcesTitle: theme.siteGroupsRelatedResourcesTitle,
    siteGroupsRelatedResourcesList: theme.siteGroupsRelatedResourcesList,
    siteGroupsRelatedResourcesIcon: theme.siteGroupsRelatedResourcesIcon,
    siteGroupsRelatedResourcesLink: theme.siteGroupsRelatedResourcesLink,
    siteGroupsFeatured: theme.siteGroupsFeatured,
    siteGroupsVideosContainer: theme.siteGroupsVideosContainer,
    siteGroupsVideos: theme.siteGroupsVideos,
    siteGroupsVideosItem: theme.siteGroupsVideosItem,
    siteGroupsVideosPlayer: theme.siteGroupsVideosPlayer,
    siteGroupsFeaturedContainer: theme.siteGroupsFeaturedContainer,
    siteGroupsVideosIcon: theme.siteGroupsVideosIcon,
    siteGroupsVideosTitle: theme.siteGroupsVideosTitle,
    siteGroupsBanner: theme.siteGroupsBanner,
    siteGroupsFeaturedTitle: theme.siteGroupsFeaturedTitle,
    siteGroupsFeaturedItem: theme.siteGroupsFeaturedItem
}));

const getSections = (nodes: IHydratedNode[]): IHydratedNode[] => {
    return nodes.reduce((acc, val) => {
        return [...acc, ...getSections(val.children)];
    }, [])
};

interface ISiteGroupsProps {
    className?: string;
    classes?: any;
    whatsNew: ISection[];
    featured: ISection[];
    videos: any[];
    relatedResources: any[];
    banners: any[];
}

class SiteGroups extends React.Component<ISiteGroupsProps & WithRouterProps> {

    static defaultProps = {
        videos: [],
        featured: [],
        whatsNew: [],
        relatedResources: [],
        banners: []
    }

    render() {
        return (<div className={`${this.props.classes.siteGroups} ${this.props.classes.index}`}>
                {this.props.children}
                <div className={this.props.classes.siteGroupsFeatured}>
                    {this.props.whatsNew?.length ? (
                        <div className={this.props.classes.siteGroupsFeaturedContainer}>
                            <I18nMessage className={this.props.classes.siteGroupsFeaturedTitle} 
                                         variant="h4"
                                         id={"label.whats-new"}/>
                            {this.props.whatsNew.map((item, i) => <SectionItem key={item?.title + i}
                                                                                className={this.props.classes.siteGroupsFeaturedItem} {...(item || {})}/>)}
                        </div>
                    ) : null}

                    {this.props.featured?.length ? (
                        <div className={this.props.classes.siteGroupsFeaturedContainer}>
                            <I18nMessage className={this.props.classes.siteGroupsFeaturedTitle} 
                                         variant="h4"
                                         id={"label.featured-articles"}/>
                            {this.props.featured.map((item, i) => <SectionItem key={item?.title + i}
                                                                               
                                                                               className={this.props.classes.siteGroupsFeaturedItem} {...(item || {})}/>)}
                        </div>
                    ) : null}

                </div>

                {this.props.videos?.length ? (
                    <div className={this.props.classes.siteGroupsVideos}>
                        <I18nMessage className={this.props.classes.siteGroupsVideosTitle} variant="h3"
                                     id={"label.featured-videos"}/>
                        <div className={this.props.classes.siteGroupsVideosContainer}>
                            {this.props.videos.map((vid, index) =>
                                (<div className={this.props.classes.siteGroupsVideosItem} key={index}>
                                    <PortalFontIcon
                                        className={`ondemand_video ${this.props.classes.siteGroupsVideosIcon}`}
                                        name="ondemand_video"/>
                                    <RenderHtml className={this.props.classes.siteGroupsVideosPlayer}>{vid?.content}</RenderHtml>
                                </div>))}
                        </div>
                    </div>
                ) : null}

                {this.props.relatedResources?.length > 0 ?
                    (<div className={this.props.classes.siteGroupsRelatedResources}>
                        <I18nMessage className={this.props.classes.siteGroupsRelatedResourcesTitle} variant="h3"
                                     id={"label.related-resources"}/>
                        <div className={this.props.classes.siteGroupsRelatedResourcesList}>
                            {this.props.relatedResources.map((obj, index) => (
                                <I18nMessage Component={PortalLink}
                                             key={index}
                                             id={obj?.title}
                                             target={obj?.target}
                                             className={this.props.classes.siteGroupsRelatedResourcesLink}
                                             href={obj?.href}>
                                    {obj?.thumbnail ? <img className={`no-alt ${this.props.classes.siteGroupsRelatedResourcesIcon}`}
                                                          alt={"thumbnail"}
                                                          src={obj.thumbnail}/> : null}
                                </I18nMessage>
                            ))}
                        </div>
                    </div>) : null}
            </div>
        );
    }
}

export default withRouter(withStyles(siteGroupsStyles, {name: "SiteGroups"})(SiteGroups as any) as any) as any;
